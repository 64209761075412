import React, { useEffect, useState, useContext } from "react";
import QRCode from "qrcode.react";
//import openSocket from "socket.io-client";
import { socket } from "../../services/socket";

import toastError from "../../errors/toastError";

import { Dialog, DialogContent, Paper, Typography } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";

import { AuthContext } from "../../context/Auth/AuthContext";

const QrcodeModal = ({ open, onClose, whatsAppId }) => {

	const { user } = useContext(AuthContext);

	const [qrCode, setQrCode] = useState("");

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`/whatsapp/${whatsAppId}`);
				setQrCode(data.qrcode);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	useEffect(() => {
		if (!whatsAppId) return;
		//const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		const onWhatsAppSessionQrCode = data => {
			if (data.action === "update" && data.session.id === whatsAppId) {
				setQrCode(data.session.qrcode);
			}

			if (data.action === "update" && data.session.qrcode === "") {
				onClose();
			}

			if (data.action === "error") {

				console.log('user.profile: ', user.profile)

				if (user.profile === 'master') {

					alert(data.msg)

				}

			}
		}

		socket.on("whatsappSession", onWhatsAppSessionQrCode);

		return () => {
			socket.off("whatsappSession", onWhatsAppSessionQrCode);
		};
	}, [whatsAppId, onClose, user.profile]);

	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
			<DialogContent>
				<Paper elevation={0}>
					<Typography color="primary" gutterBottom>
						{i18n.t("qrCode.message")}
					</Typography>
					{qrCode ? (
						<QRCode value={qrCode} size={256} />
					) : (
						<span>Waiting for QR Code</span>
					)}
				</Paper>
			</DialogContent>
		</Dialog>
	);
};

export default React.memo(QrcodeModal);
