

import React, { Fragment, useState, useEffect } from "react";


import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  KeyboardDatePicker, 
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
 
 

// import { i18n } from "../../../translate/i18n";


import ptBrLocale from "date-fns/locale/pt-BR";
import esLocale from 'date-fns/locale/es';
  
function formatDateDatePicker(data){
  return String(new Date(data).getFullYear())+'-'+
  String(new Date(data).getMonth() + 1).padStart(2,'0')+'-'+
  String(new Date(data).getDate()).padStart(2,'0')
}

function ResponsiveDatePickers(props) {
  const [selectedDate, handleDateChange] = useState(props.startEmpty? null : new Date());
 

  ////////////////////////// 
  useEffect(() => {
    if (props.reset) {
      handleDateChange(null) 
      props.setReset(false)
    }
  }, [props.reset, props.setReset, props]) 
  /////////////////////////

  useEffect(()=>{


    if( !selectedDate ){
      props.func('');
    }
    else{
        props.func(formatDateDatePicker(selectedDate));
    }
       

  }, [selectedDate, props])

  return ( 

    <Fragment> 
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <KeyboardDatePicker
          // autoOk 
        
          variant="inline"
          inputVariant="outlined"
          label={props.title}
          minDate={props.minDate? new Date() : false} 
          format="dd/MM/yyyy"
          value={selectedDate} 
          InputAdornmentProps={{ position: "start" }}
          onChange={date => handleDateChange(date)}
          
        /> 
      </MuiPickersUtilsProvider>    
    </Fragment>
  );
}

export default ResponsiveDatePickers;




/*import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider'; 
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from '@mui/material/Stack';

const ResponsiveDatePickers = (props) =>   {

  const [value, setValue] = React.useState(new Date());

  props.func(value);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={1}> 
        <DesktopDatePicker
          label={props.title}  
          
          value={value}
          minDate={new Date('2017-01-01')}
          onChange={(newValue) => {setValue(newValue)}}
          renderInput={(params) => <TextField {...params} />}
        /> 
      </Stack>
    </LocalizationProvider>
  );
}

export default ResponsiveDatePickers*/