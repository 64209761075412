import React, { useState, useEffect, useRef, useContext } from "react"

import * as Yup from "yup"
import { Formik, Form, Field } from "formik"
import { toast } from "react-toastify"
//import openSocket from 'socket.io-client'
import { socket } from "../../services/socket"

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core"
import { green } from "@material-ui/core/colors"
import { i18n } from "../../translate/i18n"
import QueueSelect from '../QueueSelect'
import { AuthContext } from '../../context/Auth/AuthContext'

import api from "../../services/api"
import toastError from "../../errors/toastError"

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: "100%",
  },
}))

const QuickAnswerSchema = Yup.object().shape({
  shortcut: Yup.string()
    .min(2, "Too Short!")
    .max(15, "Too Long!")
    .required("Required"),
  message: Yup.string()
    .min(8, "Too Short!")
    .max(30000, "Too Long!")
    .required("Required"),
})

const QuickAnswersModal = ({
  open,
  onClose,
  quickAnswerId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles()
  const isMounted = useRef(true)

  const initialState = {
    shortcut: "",
    message: "",
  }

  const [quickAnswer, setQuickAnswer] = useState(initialState)
  const [selectedQueueIds, setSelectedQueueIds] = useState([])
  const { user, setting, getSettingValue } = useContext(AuthContext)
  const [settings, setSettings] = useState(setting)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setSettings(setting)
  }, [setting])

  useEffect(() => {

    setSelectedQueueIds([])
    if (open && selectedQueueIds.length === 0 && !quickAnswerId) {
      setSelectedQueueIds(user.queues.map(q => q.id))
    }

  }, [open,])

  useEffect(() => {
    //const socket = openSocket(process.env.REACT_APP_BACKEND_URL)

    const onSettingsQuickAnswersModal = (data) => {
      if (data.action === 'update') {
        setSettings((prevState) => {
          const aux = [...prevState]
          const settingIndex = aux.findIndex((s) => s.key === data.setting.key)
          aux[settingIndex].value = data.setting.value
          return aux
        })
      }
    }

    socket.on('settings', onSettingsQuickAnswersModal)

    return () => {
      socket.off("settings", onSettingsQuickAnswersModal)
    }
  }, [])

  useEffect(() => {

    // setSelectedQueueIds([])

    const fetchQuickAnswer = async () => {
      if (initialValues) {
        setQuickAnswer((prevState) => {
          return { ...prevState, ...initialValues }
        })
      }

      if (!quickAnswerId) {
        return
      }

      try {
        const { data } = await api.get(`/quickAnswers/${quickAnswerId}`)
        if (isMounted.current) {
          setQuickAnswer(data)

          if (data?.queues) {
            const quickQueueIds = data.queues?.map((queue) => queue.id)
            setSelectedQueueIds(quickQueueIds)
          }
        }
      } catch (err) {
        toastError(err)
      }
    }

    fetchQuickAnswer()
  }, [quickAnswerId, open, initialValues])

  const handleClose = () => {
    onClose()
    setQuickAnswer(initialState)
  }

  const handleSaveQuickAnswer = async (values) => {
    try {

      values = { ...values, queueIds: selectedQueueIds }

      if (quickAnswerId) {
        await api.put(`/quickAnswers/${quickAnswerId}`, values)
        handleClose()
      } else {
        const { data } = await api.post("/quickAnswers", values)
        if (onSave) {
          onSave(data)
        }
        handleClose()
      }
      toast.success(i18n.t("quickAnswersModal.success"))
    } catch (err) {
      toastError(err)
    }
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {quickAnswerId
            ? `${i18n.t("quickAnswersModal.title.edit")}`
            : `${i18n.t("quickAnswersModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={quickAnswer}
          enableReinitialize={true}
          validationSchema={QuickAnswerSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveQuickAnswer(values)
              actions.setSubmitting(false)
            }, 400)
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textQuickAnswerContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("quickAnswersModal.form.shortcut")}
                    name="shortcut"
                    autoFocus
                    error={touched.shortcut && Boolean(errors.shortcut)}
                    helperText={touched.shortcut && errors.shortcut}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                  />
                </div>
                <div className={classes.textQuickAnswerContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("quickAnswersModal.form.message")}
                    name="message"
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>
                <div>
                  {
                    ((settings && getSettingValue('quickAnswerByQueue') === 'enabled')) && (
                      <QueueSelect
                        selectedQueueIds={selectedQueueIds}
                        onChange={(selectedIds) => {
                          return setSelectedQueueIds(selectedIds)
                        }}
                        _queues={user.queues}
                      />
                    )
                  }
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("quickAnswersModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {quickAnswerId
                    ? `${i18n.t("quickAnswersModal.buttons.okEdit")}`
                    : `${i18n.t("quickAnswersModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  )
}

export default QuickAnswersModal
