import React, { useState, useContext, useEffect, useReducer } from "react"
//import openSocket from "socket.io-client"
import { socket } from "../../services/socket"

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
} from "@material-ui/core"
import { Edit, DeleteOutline } from "@material-ui/icons"
import SearchIcon from "@material-ui/icons/Search"

import MainContainer from "../../components/MainContainer"
import MainHeader from "../../components/MainHeader"
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper"
import Title from "../../components/Title"

import api from "../../services/api"
import { i18n } from "../../translate/i18n"
import TableRowSkeleton from "../../components/TableRowSkeleton"
import PositionModal from "../../components/PositionModal"
import ConfirmationModal from "../../components/ConfirmationModal"
import { toast } from "react-toastify"
import toastError from "../../errors/toastError"
import { AuthContext } from '../../context/Auth/AuthContext'

const reducer = (state, action) => {
  if (action.type === "LOAD_POSITIONS") {
    const positions = action.payload
    const newPositions = []

    positions.forEach((position) => {
      const positionIndex = state.findIndex((q) => q.id === position.id)
      if (positionIndex !== -1) {
        state[positionIndex] = position
      } else {
        newPositions.push(position)
      }
    })

    return [...state, ...newPositions]
  }

  if (action.type === "UPDATE_POSITIONS") {
    const position = action.payload
    const positionIndex = state.findIndex((q) => q.id === position.id)

    if (positionIndex !== -1) {
      state[positionIndex] = position
      return [...state]
    } else {
      return [position, ...state]
    }
  }

  if (action.type === "DELETE_POSITIONS") {
    const positionId = action.payload

    const positionIndex = state.findIndex((q) => q.id === positionId)
    if (positionIndex !== -1) {
      state.splice(positionIndex, 1)
    }
    return [...state]
  }

  if (action.type === "RESET") {
    return []
  }
}

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}))

const Position = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchParam, setSearchParam] = useState("")
  const [positions, dispatch] = useReducer(reducer, [])
  const [selectedPosition, setSelectedPosition] = useState(null)
  const [positionModalOpen, setPositionModalOpen] = useState(false)
  const [deletingPosition, setDeletingPosition] = useState(null)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const { user, } = useContext(AuthContext)


  useEffect(() => {
    dispatch({ type: "RESET" })
    setPageNumber(1)
  }, [searchParam])

  useEffect(() => {
    setLoading(true)
    const delayDebounceFn = setTimeout(() => {
      const fetchPositions = async () => {
        try {
          const { data } = await api.get("/positions/", {
            params: { searchParam, pageNumber },
          })
          dispatch({ type: "LOAD_POSITIONS", payload: data.positions })
          setHasMore(data.hasMore)
          setLoading(false)
        } catch (err) {
          toastError(err)
        }
      }
      fetchPositions()
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [searchParam, pageNumber])

  useEffect(() => {
    //const socket = openSocket(process.env.REACT_APP_BACKEND_URL)

    const onPositionPosition = (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_POSITIONS", payload: data.position })
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_POSITIONS",
          payload: +data.positionId,
        })
      }
    }

    socket.on("position", onPositionPosition)

    return () => {
      socket.off("position", onPositionPosition)
    }
  }, [])

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase())
  }

  const handleOpenPositionModal = () => {
    setSelectedPosition(null)
    setPositionModalOpen(true)
  }

  const handleClosePositionModal = () => {
    setSelectedPosition(null)
    setPositionModalOpen(false)
  }

  const handleEditPosition = (position) => {
    setSelectedPosition(position)
    setPositionModalOpen(true)
  }

  const handleDeletePosition = async (positionId) => {
    try {
      await api.delete(`/positions/${positionId}`)
      toast.success("Cargo deletado com sucesso")
    } catch (err) {
      toastError(err)
    }
    setDeletingPosition(null)
    setSearchParam("")
    setPageNumber(1)
  }

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1)
  }

  const handleScroll = (e) => {
    if (!hasMore || loading) return
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore()
    }
  }

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingPosition &&
          `Você tem certeza que quer excluir este cargo: ${deletingPosition.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeletePosition(deletingPosition.id)}
      >
        {i18n.t("quickAnswers.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <PositionModal
        open={positionModalOpen}
        onClose={handleClosePositionModal}
        aria-labelledby="form-dialog-title"
        positionId={selectedPosition && selectedPosition.id}
      ></PositionModal>
      <MainHeader>
        <Title>{"Cargo"}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("quickAnswers.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenPositionModal}
          >
            {"Adicionar cargo"}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                Cargo
              </TableCell>
              <TableCell align="center">
                {i18n.t("quickAnswers.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {positions.map((position) => (
                <TableRow key={position.id}>
                  <TableCell align="center">{position.name}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditPosition(position)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true)
                        setDeletingPosition(position)
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  )
}

export default Position
