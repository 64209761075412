import React, { useState, useEffect, useContext } from 'react'
// import * as Yup from 'yup'
import { Formik, Form, Field, } from 'formik'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

import { TimePicker, DatePicker } from 'formik-material-ui-pickers'

import DateFnsUtils from '@date-io/date-fns'

import ptBrLocale from "date-fns/locale/pt-BR"
import esLocale from 'date-fns/locale/es';

import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext"

import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    DialogActions,
    CircularProgress,
    TextField,
    Switch,
    FormControlLabel,
    Divider,
} from '@material-ui/core'

import api from '../../services/api'
import { i18n } from '../../translate/i18n'
import toastError from '../../errors/toastError'

import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    multFieldLine: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

// const SessionSchema = Yup.object().shape({
//     name: Yup.string()
//         .min(2, 'Too Short!')
//         .max(100, 'Too Long!')
//         .required('Required'),
// })

const ConfigModal = ({ open, onClose, change }) => {
    const classes = useStyles()
    const initialState = {
        startTimeBus: new Date(),
        endTimeBus: new Date(),

        startTimeBusSaturday: new Date(),
        endTimeBusSaturday: new Date(),

        messageBus: '',
        messageBusSaturday: '',

        businessTimeEnable: false,
        businessTimeEnableSaturday: false,

        ticketTimeExpiration: new Date(),
        ticketExpirationMsg: '',
        ticketExpirationEnable: false,
        holidayDate: new Date(),
        holidayDateEnable: false,
        holidayDateMessage: '',
        checkboxSundayValue: false,
        checkboxSaturdayValue: false,
        weekendMessage: '',
        enableWeekendMessage: false
    }

    const { whatsApps } = useContext(WhatsAppsContext)
    const [selectedNumber, setSelectedNumber] = useState('')

    const [config, setConfig] = useState(initialState)


    useEffect(() => {
        console.log('selectedNumber: ', selectedNumber)

        if (selectedNumber?.trim().length === 0) {
            setConfig(initialState)
        }

    }, [selectedNumber])

    useEffect(() => {
        const fetchSession = async () => {

            try {
                // const { data } = await api.get('/settings')  

                if (!selectedNumber) return

                const { data } = await api.get(`/settings/ticket/${selectedNumber}`)

                if (data?.config && data.config.length === 0) {
                    setConfig(initialState)
                    return
                }

                const outBusinessHours = data.config.find((c) => c.key === "outBusinessHours")

                const saturdayBusinessTime = data.config.find((c) => c.key === "saturdayBusinessTime")

                const ticketExpiration = data.config.find((c) => c.key === "ticketExpiration")
                const saturday = data.config.find((c) => c.key === "saturday")
                const sunday = data.config.find((c) => c.key === "sunday")
                const weekend = data.config.find((c) => c.key === "weekend")
                const holiday = data.config.find((c) => c.key === "holiday")

                setConfig({
                    startTimeBus: outBusinessHours.startTime,
                    endTimeBus: outBusinessHours.endTime,
                    messageBus: outBusinessHours.message,
                    businessTimeEnable: outBusinessHours.value === 'enabled' ? true : false,

                    startTimeBusSaturday: saturdayBusinessTime.startTime,
                    endTimeBusSaturday: saturdayBusinessTime.endTime,
                    messageBusSaturday: saturdayBusinessTime.message,
                    businessTimeEnableSaturday: saturdayBusinessTime.value === 'enabled' ? true : false,

                    ticketTimeExpiration: ticketExpiration.startTime,
                    ticketExpirationMsg: ticketExpiration.message,
                    ticketExpirationEnable: ticketExpiration.value === 'enabled' ? true : false,

                    checkboxSaturdayValue: saturday.value === 'enabled' ? true : false,
                    checkboxSundayValue: sunday.value === 'enabled' ? true : false,
                    weekendMessage: weekend.message,
                    enableWeekendMessage: weekend.value === 'enabled' ? true : false,

                    holidayDate: holiday.startTime,
                    holidayDateMessage: holiday.message,
                    holidayDateEnable: holiday.value === 'enabled' ? true : false,
                })

            } catch (err) {
                toastError(err)
            }
        }
        fetchSession()
    }, [change, selectedNumber])

    const handleSaveConfig = async (values) => {

        values = {
            number: selectedNumber,
            outBusinessHours: {
                startTime: values.startTimeBus,
                endTime: values.endTimeBus,
                message: values.messageBus,
                value: values.businessTimeEnable ? 'enabled' : 'disabled'
            },

            saturdayBusinessTime: {
                startTime: values.startTimeBusSaturday,
                endTime: values.endTimeBusSaturday,
                message: values.messageBusSaturday,
                value: values.businessTimeEnableSaturday ? 'enabled' : 'disabled'
            },

            ticketExpiration: {
                startTime: values.ticketTimeExpiration,
                message: values.ticketExpirationMsg,
                value: values.ticketExpirationEnable ? 'enabled' : 'disabled'
            },
            weekend: {
                message: values.weekendMessage,
                value: values.enableWeekendMessage ? 'enabled' : 'disabled'
            },
            saturday: {
                value: values.checkboxSaturdayValue ? 'enabled' : 'disabled'
            },
            sunday: {
                value: values.checkboxSundayValue ? 'enabled' : 'disabled'
            },
            holiday: {
                startTime: values.holidayDate,
                message: values.holidayDateMessage,
                value: values.holidayDateEnable ? 'enabled' : 'disabled'
            }

        }


        try {

            await api.put(`/settings/ticket`, values)

            toast.success('Atualização realizada com sucesso!')
            handleClose()

        } catch (err) {
            toastError(err)
        }
    }

    const handleClose = () => {
        onClose()
        // setConfig(initialState)
    }
  
    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                scroll="paper"
            >
                <DialogTitle>
                    Configurações
                </DialogTitle>


                <Formik
                    initialValues={config}
                    enableReinitialize={true}
                    // validationSchema={SessionSchema} 
                    onSubmit={(values, actions) => {

                        setTimeout(() => {
                            handleSaveConfig(values)
                            actions.setSubmitting(false)
                        }, 100)
                    }}
                >
                    {({ values, touched, errors, isSubmitting }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                            <Form>

                                <DialogContent dividers>

                                    <div>
                                        <Select
                                            value={selectedNumber}
                                            onChange={(e) => setSelectedNumber(e.target.value)}
                                            label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
                                            required
                                        >
                                            <MenuItem style={{ background: "white", }} value={''}>&nbsp;</MenuItem>

                                            {whatsApps.reduce((acc, curr) => {
                                                const existingObject = acc.find(item => item.number === curr.number)

                                                if (!existingObject) {
                                                    acc.push(curr)
                                                }

                                                return acc
                                            }, []).map((whatsapp) => (
                                                <MenuItem
                                                    key={whatsapp.id}
                                                    value={whatsapp.number}
                                                >
                                                    {whatsapp.number}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>



                                    <Divider />
                                    <br />

                                    <div className={classes.multFieldLine}>
                                        <Field
                                            component={TimePicker}
                                            name="startTimeBus"
                                            label={i18n.t('configModal.titles.startService')}
                                            ampm={false}
                                            openTo="hours"
                                            views={['hours', 'minutes',]}
                                            format="HH:mm"
                                        />
                                        {' '}
                                        <Field
                                            component={TimePicker}
                                            name="endTimeBus"
                                            label={i18n.t('configModal.titles.endService')}
                                            ampm={false}
                                            openTo="hours"
                                            views={['hours', 'minutes',]}
                                            format="HH:mm"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="businessTimeEnable"
                                                    checked={values.businessTimeEnable}
                                                />
                                            }
                                            label={i18n.t('configModal.titles.enableDisable')} />
                                    </div>

                                    <div>
                                        <Field
                                            as={TextField}
                                            label={i18n.t('configModal.titles.outOfHours')}
                                            type="messageBus"
                                            multiline
                                            rows={5}
                                            fullWidth
                                            name="messageBus"
                                            error={
                                                touched.messageBus && Boolean(errors.messageBus)
                                            }
                                            helperText={
                                                touched.messageBus && errors.messageBus
                                            }
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </div>


                                    <br />
                                    {/* SABADO INICIO */}
                                    <div className={classes.multFieldLine}>
                                        <Field
                                            component={TimePicker}
                                            name="startTimeBusSaturday"
                                            label={i18n.t('configModal.titles.startService')}
                                            ampm={false}
                                            openTo="hours"
                                            views={['hours', 'minutes',]}
                                            format="HH:mm"
                                        />
                                        {' '}
                                        <Field
                                            component={TimePicker}
                                            name="endTimeBusSaturday"
                                            label={i18n.t('configModal.titles.endService')}
                                            ampm={false}
                                            openTo="hours"
                                            views={['hours', 'minutes',]}
                                            format="HH:mm"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="businessTimeEnableSaturday"
                                                    checked={values.businessTimeEnableSaturday}
                                                />
                                            }
                                            label={i18n.t('configModal.titles.enableDisable')} />
                                    </div>

                                    <div>
                                        <Field
                                            as={TextField}
                                            label={i18n.t('configModal.titles.outOfHoursSaturday')}
                                            type="messageBusSaturday"
                                            multiline
                                            rows={5}
                                            fullWidth
                                            name="messageBusSaturday"
                                            error={
                                                touched.messageBusSaturday && Boolean(errors.messageBusSaturday)
                                            }
                                            helperText={
                                                touched.messageBusSaturday && errors.messageBusSaturday
                                            }
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </div>
                                    {/* SABADO FIM */}
                                    <br />
 

                                    {/* Saturday and Sunday date */}
                                    <div className={classes.multFieldLine} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <label style={{ marginRight: '10px' }}>
                                                <Field type="checkbox" name="checkboxSundayValue" />
                                                Sábado
                                            </label>

                                            <label>
                                                <Field type="checkbox" name="checkboxSaturdayValue" />
                                                Domingo
                                            </label>
                                        </div>

                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="enableWeekendMessage"
                                                    checked={values.enableWeekendMessage}
                                                />
                                            }
                                            label={i18n.t('configModal.titles.enableDisable')}
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            as={TextField}
                                            label={i18n.t('configModal.titles.forTheWeekend')}
                                            type="weekendMessage"
                                            multiline
                                            rows={5}
                                            fullWidth
                                            name="weekendMessage"
                                            error={
                                                touched.weekendMessage && Boolean(errors.weekendMessage)
                                            }
                                            helperText={
                                                touched.weekendMessage && errors.weekendMessage
                                            }
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </div>

                                    <br />

                                    {/* Holiday date */}
                                    <div className={classes.multFieldLine} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                        <Field
                                            component={DatePicker}
                                            name="holidayDate"
                                            label={i18n.t('configModal.titles.holiday')}
                                            format="dd/MM/yyyy"
                                            fullWidth
                                        />

                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="holidayDateEnable"
                                                    checked={values.holidayDateEnable}
                                                />
                                            }
                                            label={i18n.t('configModal.titles.enableDisable')}
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            as={TextField}
                                            label={i18n.t('configModal.titles.holidayMessage')}
                                            type="holidayDateMessage"
                                            multiline
                                            rows={5}
                                            fullWidth
                                            name="holidayDateMessage"
                                            error={
                                                touched.holidayDateMessage && Boolean(errors.holidayDateMessage)
                                            }
                                            helperText={
                                                touched.holidayDateMessage && errors.holidayDateMessage
                                            }
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </div>






                                    <br />
                                    <div className={classes.multFieldLine} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Field
                                            component={TimePicker}
                                            name="ticketTimeExpiration"
                                            label="Ticket expira em hh:mm"
                                            ampm={false}
                                            openTo="hours"
                                            views={['hours', 'minutes',]}
                                            format="HH:mm"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="ticketExpirationEnable"
                                                    checked={values.ticketExpirationEnable}
                                                />
                                            }
                                            label={i18n.t('configModal.titles.enableDisable')}
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            as={TextField}
                                            label={i18n.t('configModal.titles.inactivityMessage')}
                                            type="ticketExpirationMsg"
                                            multiline
                                            rows={5}
                                            fullWidth
                                            name="ticketExpirationMsg"
                                            error={
                                                touched.ticketExpirationMsg && Boolean(errors.ticketExpirationMsg)
                                            }
                                            helperText={
                                                touched.ticketExpirationMsg && errors.ticketExpirationMsg
                                            }
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </div>

                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="secondary"
                                        disabled={isSubmitting}
                                        variant="outlined"
                                    >
                                        {i18n.t('whatsappModal.buttons.cancel')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}
                                    >
                                        {isSubmitting ? (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        ) : i18n.t('configModal.titles.save')}
                                    </Button>
                                </DialogActions>
                            </Form>
                        </MuiPickersUtilsProvider>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}





export default React.memo(ConfigModal)
