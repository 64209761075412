import React from "react";

import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

// import Tooltip from "@mui/material/Tooltip";
// import Zoom from "@mui/material/Zoom";

import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";

import { i18n } from "../../translate/i18n";

const TableUser = ({ classes, usersOnlineInfo, logout }) => {
  const [search, setSearch] = React.useState("");
  const [filterStatus, setFilterStatus] = React.useState(null);

  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };
  const handlesearch = (event) => {
    setSearch(event.target.value.toLowerCase());
  };

  return (
    <Grid item xs={12}>
      <Paper className={classes.cardPaperFix} sx={12} variant="outlined">
        <Grid container sx={12} justifyContent="space-between" alignItems="baseline">
          <Grid item sx={4}>
            <Typography
              component="h4"
              variant="h6"
              color="primary"
              style={{ marginBottom: "16px" }}
            >
           
              {i18n.t("dashboard.table_users.title")}
            </Typography>
          </Grid>
          <Grid item sx={8} width="100%">
            <Box sx={{ marginBottom: 2, display: "flex", gap: "12px" }}>
              <TextField
                id="outlined-basic"
                label="Usuário"
                variant="standard"
                value={search}
                onChange={handlesearch}
              />
              <FormControl fullWidth variant="standard">
                <InputLabel id="status">Status</InputLabel>
                <Select
                  labelId="status"
                  id="status"
                  value={filterStatus}
                  label="Status"
                  onChange={handleFilterChange}
                >
                  <MenuItem value={null}>Todos</MenuItem>
                  <MenuItem value={"online"}>Online</MenuItem>
                  <MenuItem value={"offline"}>Offline</MenuItem>
                  <MenuItem value={"not"}>{i18n.t('dashboard.titles.notEnter')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: "16px", marginBottom: "16px" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className={classes.tableRowHead}>
                  <TableCell>{i18n.t("dashboard.table_users.column0")}</TableCell>
                  <TableCell>{i18n.t("dashboard.table_users.column1")}</TableCell>
                  <TableCell>{i18n.t("dashboard.table_users.column2")}</TableCell>
                  <TableCell>{i18n.t("dashboard.table_users.column3")}</TableCell>
                  <TableCell>{i18n.t("dashboard.table_users.column4")}</TableCell>
                  <TableCell>{i18n.t("dashboard.table_users.column5")}</TableCell>
                </TableRow>
              </TableHead>
              
              
              <TableBody style={{ overflowY: "scroll" }}>
                {usersOnlineInfo &&
                  usersOnlineInfo
                    .filter((e) => {
                      if (filterStatus === null) return e;
                      if (filterStatus === "not") return !e.statusOnline;
                      return e.statusOnline && e.statusOnline.status === filterStatus;
                    })
                    .filter((e) => {
                      return e.name.toLowerCase().includes(search);
                    })
                    .sort((a) => {
                      if (a.statusOnline) {
                        if (a.statusOnline.status === "online") {
                          return -1;
                        }
                        return 0;
                      }
                      return 0;
                    })
                    .map((user, index) => (
                      <TableRow key={index} className={classes.tableRowBody}>
                        <TableCell>
                          <Typography
                            style={{ display: "flex", verticalAlign: "center", gap: "6px" }}
                          >
                            {user.statusOnline ? (
                              user.statusOnline.status === "online" ? (
                                <CheckCircleIcon style={{ color: "green" }} />
                              ) : user.statusOnline.status === "offline" ? (
                                <CancelIcon style={{ color: "red" }} />
                              ) : (
                                <ErrorIcon style={{ color: "gold" }} />
                              )
                            ) : (
                              <RemoveCircleIcon style={{ color: "black" }} />
                            )}
                            {user.name}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                            <Typography className={classes.tableCounterOpen} title="Em Atendimento">
                              {user.sumOpen
                                ? user.sumOpen.count !== ""
                                  ? user.sumOpen.count
                                  : 0
                                : 0}
                            </Typography>
                            <Typography className={classes.tableCounterClosed} title="Finalzados">
                              {user.sumClosed
                                ? user.sumClosed.count !== ""
                                  ? user.sumClosed.count
                                  : 0
                                : 0}
                            </Typography>
                          </div>
                        </TableCell>

                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            {user.openClosedInQueue &&
                              user.openClosedInQueue.map((queue) => (
                                <Typography
                                  key={queue.queueId}
                                  className={classes.tableQueues}
                                  title={queue.name}
                                  style={{ backgroundColor: queue.color }}
                                >
                                  {queue.countOpen ? queue.countOpen : "0"}
                                </Typography>
                              ))}
                            <Typography
                              className={classes.tableQueues}
                              title="Sem Fila"
                              style={{ backgroundColor: "grey" }}
                            >
                              {user.openClosedOutQueue.countOpen === 0
                                ? 0
                                : user.openClosedOutQueue.countOpen}
                            </Typography>
                          </div>
                        </TableCell>

                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            {user.openClosedInQueue &&
                              user.openClosedInQueue.map((queue) => (
                                <Typography
                                  key={queue.queueId}
                                  className={classes.tableQueues}
                                  title={queue.name}
                                  style={{ backgroundColor: queue.color }}
                                >
                                  {queue.countClosed ? queue.countClosed : "0"}
                                </Typography>
                              ))}
                            <Typography
                              className={classes.tableQueues}
                              title="Sem Fila"
                              style={{ backgroundColor: "grey" }}
                            >
                              {user.openClosedOutQueue.countClosed === 0
                                ? 0
                                : user.openClosedOutQueue.countClosed}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          {user.sumOnlineTime ? user.sumOnlineTime.sum : i18n.t('dashboard.titles.notEnter')}
                        </TableCell>
                        <TableCell>
                          {user.statusOnline && user.statusOnline.status === "online" ? (
                            <PowerSettingsNewIcon
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={(e) => {
                                logout(user.id);
                              }}
                            />
                          ) : (
                            <PowerSettingsNewIcon
                              style={{ color: "grey", cursor: "not-allowed" }}
                              title="Agente Não Logado"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default TableUser;
