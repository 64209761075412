import React, { useState, useCallback, useEffect, useContext } from 'react'
import { toast } from 'react-toastify'
import { format, parseISO } from 'date-fns'

//import openSocket from 'socket.io-client'
import { socket } from '../../services/socket'

import { makeStyles } from '@material-ui/core/styles'
import { green, red, yellow, grey } from '@material-ui/core/colors'

import Settings from "@material-ui/icons/Settings"

import {
  Button,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  Paper,
  Tooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import {
  Edit,
  CheckCircle,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet0Bar,
  FiberManualRecord,
  SignalCellular4Bar,
  CropFree,
  DeleteOutline,
  // Restore
} from '@material-ui/icons'

import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper'
import Title from '../../components/Title'
import TableRowSkeleton from '../../components/TableRowSkeleton'

import api from '../../services/api'
import WhatsAppModal from '../../components/WhatsAppModal'
import ConfirmationModal from '../../components/ConfirmationModal'
import QrcodeModal from '../../components/QrcodeModal'
import { i18n } from '../../translate/i18n'
import { WhatsAppsContext } from '../../context/WhatsApp/WhatsAppsContext'
import toastError from '../../errors/toastError'

//--------
import { AuthContext } from '../../context/Auth/AuthContext'
import { Can } from '../../components/Can'
import ConfigModal from '../../components/ConfigModal'

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: 'scroll',
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
    maxWidth: 450,
  },
  tooltipPopper: {
    textAlign: 'center',
  },
  buttonProgress: {
    color: green[500],
  },
}))

const CustomToolTip = ({ title, content, children }) => {
  const classes = useStyles()

  return (
    <Tooltip
      arrow
      classes={{
        tooltip: classes.tooltip,
        popper: classes.tooltipPopper,
      }}
      title={
        <React.Fragment>
          <Typography gutterBottom color="inherit">
            {title}
          </Typography>
          {content && <Typography>{content}</Typography>}
        </React.Fragment>
      }
    >
      {children}
    </Tooltip>
  )
}

const whatsAppClasssification = ({ isOfficial, classification }) => {

  if (isOfficial && classification) {

    if (classification === 'GREEN')
      return <CustomToolTip title={'Qualidade alta'}>
        <FiberManualRecord style={{ color: green[500] }} />
      </CustomToolTip>

    if (classification === 'YELLOW')
      return <CustomToolTip title={'Qualidade média'}>
        <FiberManualRecord style={{ color: yellow[500] }} />
      </CustomToolTip>

    if (classification === 'RED')
      return <CustomToolTip title={'Qualidade baixa'}>
        <FiberManualRecord style={{ color: red[500] }} />
      </CustomToolTip>
  }
  else {
    return <CustomToolTip title={i18n.t('connections.toolTips.connected.title')}>
      <SignalCellular4Bar style={{ color: green[500] }} />
    </CustomToolTip>
  }

}

const Connections = () => {
  //--------
  const { user } = useContext(AuthContext)

  const classes = useStyles()

  const { whatsApps, loading } = useContext(WhatsAppsContext)
  const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false)
  const [configModalOpen, setConfigModalOpen] = useState(false)
  const [qrModalOpen, setQrModalOpen] = useState(false)
  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const [diskSpaceInfo, setDiskSpaceInfo] = useState({})

  const [settings, setSettings] = useState([])

  const [buttons, setClicks] = useState([])

  const confirmationModalInitialState = {
    action: '',
    title: '',
    message: '',
    whatsAppId: '',
    open: false,
  }
  const [confirmModalInfo, setConfirmModalInfo] = useState(
    confirmationModalInitialState
  )

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data } = await api.get('/settings')

        setSettings(data.settings)
      } catch (err) {
        toastError(err)
      }
    }
    fetchSession()
  }, [])

  const getSettingValue = (key) => {
    const { value } = settings.find((s) => s.key === key)
    return value
  }

  const handleStartWhatsAppSession = async (whatsAppId) => {
    try {
      await api.post(`/whatsappsession/${whatsAppId}`)
    } catch (err) {
      toastError(err)
    }
  }

  const handleRestartWhatsAppSession = async (whatsapp) => {

    try {
      whatsapp.disabled = true

      setClicks([...buttons, whatsapp.id])

      function enable_button(whatsappId) {
        setClicks((buttons) =>
          buttons.filter((id) => {
            return +id !== +whatsappId
          })
        )
      }

      setTimeout(enable_button, 25000, whatsapp.id)

      await api.post(`/restartwhatsappsession/${whatsapp.id}`)
    } catch (err) {
      toastError(err)
    }
  }

  useEffect(() => {
    // whatsApps.map((e) => {
    // 	if (buttons.includes(e.id)) {
    // 		e.disabled = true
    // 	}
    // })

    for (let i = 0; i < whatsApps.length; i++) {
      if (buttons.includes(whatsApps[i].id)) {
        whatsApps[i].disabled = true
      }
    }
  }, [whatsApps, buttons])

  const handleRequestNewQrCode = async (whatsAppId) => {
    try {
      await api.put(`/whatsappsession/${whatsAppId}`)
    } catch (err) {
      toastError(err)
    }
  }

  const handleOpenWhatsAppModal = () => {
    setSelectedWhatsApp(null)
    setWhatsAppModalOpen(true)
  }

  const handleOpenConfigModal = () => {
    setConfigModalOpen(true)
  }

  const handleCloseConfigModal = () => {
    setConfigModalOpen(false)
  }
  const handleCloseWhatsAppModal = useCallback(() => {
    setWhatsAppModalOpen(false)
    setSelectedWhatsApp(null)
  }, [setSelectedWhatsApp, setWhatsAppModalOpen])

  const handleOpenQrModal = (whatsApp) => {
    setSelectedWhatsApp(whatsApp)
    setQrModalOpen(true)
  }

  const handleCloseQrModal = useCallback(() => {
    setSelectedWhatsApp(null)
    setQrModalOpen(false)
  }, [setQrModalOpen, setSelectedWhatsApp])

  const handleEditWhatsApp = (whatsApp) => {
    setSelectedWhatsApp(whatsApp)
    setWhatsAppModalOpen(true)
  }

  const handleOpenConfirmationModal = (action, whatsAppId) => {
    if (action === 'disconnect') {
      setConfirmModalInfo({
        action: action,
        title: i18n.t('connections.confirmationModal.disconnectTitle'),
        message: i18n.t('connections.confirmationModal.disconnectMessage'),
        whatsAppId: whatsAppId,
      })
    }

    if (action === 'delete') {
      setConfirmModalInfo({
        action: action,
        title: i18n.t('connections.confirmationModal.deleteTitle'),
        message: i18n.t('connections.confirmationModal.deleteMessage'),
        whatsAppId: whatsAppId,
      })
    }
    setConfirmModalOpen(true)
  }

  const handleSubmitConfirmationModal = async () => {
    if (confirmModalInfo.action === 'disconnect') {
      try {
        await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`)
      } catch (err) {
        toastError(err)
      }
    }

    if (confirmModalInfo.action === 'delete') {
      try {
        await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`)
        toast.success(i18n.t('connections.toasts.deleted'))
      } catch (err) {
        toastError(err)
      }
    }

    setConfirmModalInfo(confirmationModalInitialState)
  }

  const renderActionButtons = (whatsApp) => {

    if (whatsApp.isOfficial) return

    return (
      <Can
        role={user.profile}
        perform="connection-button:show"
        yes={() => (
          <>
            {whatsApp.status === 'qrcode' && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => handleOpenQrModal(whatsApp)}
              >
                {i18n.t('connections.buttons.qrcode')}
              </Button>
            )}
            {whatsApp.status === 'DISCONNECTED' && (
              <>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleStartWhatsAppSession(whatsApp.id)}
                >
                  {i18n.t('connections.buttons.tryAgain')}
                </Button>{' '}
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleRequestNewQrCode(whatsApp.id)}
                >
                  {i18n.t('connections.buttons.newQr')}
                </Button>
              </>
            )}
            {(whatsApp.status === 'CONNECTED' ||
              whatsApp.status === 'PAIRING' ||
              whatsApp.status === 'TIMEOUT') && (
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    handleOpenConfirmationModal('disconnect', whatsApp.id)
                  }}
                >
                  {i18n.t('connections.buttons.disconnect')}
                </Button>
              )}
            {whatsApp.status === 'OPENING' && (
              <Button size="small" variant="outlined" disabled color="default">
                {i18n.t('connections.buttons.connecting')}
              </Button>
            )}
          </>
        )}
      />
    )
  }

  const renderStatusToolTips = (whatsApp) => {
    return (
      <div className={classes.customTableCell}>
        {whatsApp.status === 'DISCONNECTED' && (
          <CustomToolTip
            title={i18n.t('connections.toolTips.disconnected.title')}
            content={i18n.t('connections.toolTips.disconnected.content')}
          >
            <SignalCellularConnectedNoInternet0Bar color="secondary" />
          </CustomToolTip>
        )}
        {whatsApp.status === 'OPENING' && whatsApp.isOfficial && (settings &&
          settings.length > 0 &&
          getSettingValue('whatsaAppCloudApi') &&
          getSettingValue('whatsaAppCloudApi') === 'disabled') && (
            <CustomToolTip title={'Whatsapp Cloud API está desativado'}>
              <FiberManualRecord style={{ color: grey[500] }} />
            </CustomToolTip>
          )}
        {whatsApp.status === 'OPENING' && !whatsApp.isOfficial && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        {whatsApp.status === 'qrcode' && (
          <CustomToolTip
            title={i18n.t('connections.toolTips.qrcode.title')}
            content={i18n.t('connections.toolTips.qrcode.content')}
          >
            <CropFree />
          </CustomToolTip>
        )}

        {whatsApp.status === 'CONNECTED' && (
          whatsAppClasssification({ ...whatsApp, })
        )}

        {(whatsApp.status === 'TIMEOUT' || whatsApp.status === 'PAIRING') && (
          <CustomToolTip
            title={i18n.t('connections.toolTips.timeout.title')}
            content={i18n.t('connections.toolTips.timeout.content')}
          >
            <SignalCellularConnectedNoInternet2Bar color="secondary" />
          </CustomToolTip>
        )}

        {/* {whatsApp.status === "RESTORING" && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.disconnected.title")}
						content={i18n.t("connections.toolTips.disconnected.content")}
					>
						<Restore color="secondary" />
					</CustomToolTip>
				)} */}
      </div>
    )
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchQueries = async () => {
        try {
          await api.post(`/restartwhatsappsession/0`, {
            params: { status: 'status' },
          })

          setClicks((buttons) =>
            buttons.map((e) => {
              return { id: e.id, disabled: false }
            })
          )
        } catch (err) {
          console.log(err)
        }
      }

      fetchQueries()
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [])

  useEffect(() => {
    //const socket = openSocket(process.env.REACT_APP_BACKEND_URL)

    const onDiskSpaceMonitConnections = (data) => {
      if (data.action === 'update') {
        setDiskSpaceInfo(data.diskSpace)
      }
    }

    socket.on('diskSpaceMonit', onDiskSpaceMonitConnections)

    const onSettingsConnections = (data) => {
      if (data.action === 'update') {
        setSettings((prevState) => {
          const aux = [...prevState]
          const settingIndex = aux.findIndex((s) => s.key === data.setting.key)
          aux[settingIndex].value = data.setting.value
          return aux
        })
      }
    }

    socket.on('settings', onSettingsConnections)

    return () => {
      socket.off("settings", onSettingsConnections)
      socket.off("diskSpaceMonit", onDiskSpaceMonitConnections)
    }
  }, [])

  return (
    <Can
      role={user.profile}
      perform="connections-view:show"
      yes={() => (
        <MainContainer>
          <ConfirmationModal
            title={confirmModalInfo.title}
            open={confirmModalOpen}
            onClose={setConfirmModalOpen}
            onConfirm={handleSubmitConfirmationModal}
          >
            {confirmModalInfo.message}
          </ConfirmationModal>

          <QrcodeModal
            open={qrModalOpen}
            onClose={handleCloseQrModal}
            whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
          />

          <WhatsAppModal
            open={whatsAppModalOpen}
            onClose={handleCloseWhatsAppModal}
            whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
            whatsAppOfficial={(settings &&
              settings.length > 0 &&
              getSettingValue('whatsaAppCloudApi') &&
              getSettingValue('whatsaAppCloudApi') === 'enabled') ? true : false}
          />

          <ConfigModal
            open={configModalOpen}
            onClose={handleCloseConfigModal}
            change={configModalOpen}
          />

          <MainHeader>
            <Title>{i18n.t('connections.title')}</Title>

            <MainHeaderButtonsWrapper>

              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenConfigModal}
              >
                <Settings />
              </Button>
              <Can
                role={user.profile}
                perform="btn-add-whatsapp"
                updatedAt
                yes={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenWhatsAppModal}
                  >
                    {i18n.t('connections.buttons.add')}
                  </Button>
                )}
              />
            </MainHeaderButtonsWrapper>
          </MainHeader>

          <Paper className={classes.mainPaper} variant="outlined">
            <>
              <Can
                role={user.profile}
                perform="space-disk-info:show"
                yes={() => (
                  <>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Size</TableCell>
                          <TableCell align="center">Used</TableCell>
                          <TableCell align="center">Available</TableCell>
                          <TableCell align="center">Use%</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell align="center">
                            {diskSpaceInfo.size}
                          </TableCell>
                          <TableCell align="center">
                            {diskSpaceInfo.used}
                          </TableCell>
                          <TableCell align="center">
                            {diskSpaceInfo.available}
                          </TableCell>
                          <TableCell align="center">
                            {diskSpaceInfo.use}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <br />
                  </>
                )}
              />

              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {i18n.t('connections.table.name')}
                    </TableCell>

                    <TableCell align="center">
                      {i18n.t('connections.table.status')}
                    </TableCell>

                    <Can
                      role={user.profile}
                      perform="connection-button:show"
                      yes={() => (
                        <TableCell align="center">
                          {i18n.t('connections.table.session')}
                        </TableCell>
                      )}
                    />

                    <Can
                      role={user.profile}
                      perform="connection-button:show"
                      yes={() => <TableCell align="center">Restore</TableCell>}
                    />

                    {/* <Can
                      role={user.profile}
                      perform="connection-button:show"
                      yes={() => (
                        <TableCell align="center">Session MB</TableCell>
                      )}
                    /> */}

                    <TableCell align="center">
                      {i18n.t('connections.table.lastUpdate')}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t('connections.table.default')}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t('connections.table.actions')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRowSkeleton />
                  ) : (
                    <>
                      {whatsApps?.length > 0 &&
                        whatsApps.map((whatsApp) => {

                          let disabledRow = {}
                          let disabled = false

                          if (whatsApp?.isOfficial && ((settings &&
                            settings.length > 0 &&
                            getSettingValue('whatsaAppCloudApi') &&
                            getSettingValue('whatsaAppCloudApi') === 'disabled'))) {
                            disabledRow = {
                              'opacity': '0.5',
                              // 'pointer-events': 'none'
                            }
                            disabled = true
                          }

                          return (
                            <TableRow key={whatsApp.id} style={disabledRow}>
                              <TableCell align="center">
                                {whatsApp.name}
                              </TableCell>

                              <TableCell align="center">
                                {renderStatusToolTips(whatsApp)}
                              </TableCell>

                              <Can
                                role={user.profile}
                                perform="connection-button:show"
                                yes={() => (
                                  <TableCell align="center">
                                    {renderActionButtons(whatsApp)}
                                  </TableCell>
                                )}
                              />

                              <Can
                                role={user.profile}
                                perform="connection-button:show"
                                yes={() => (
                                  <TableCell align="center">

                                    {!whatsApp?.isOfficial &&
                                      <Button
                                        disabled={
                                          whatsApp.disabled || disabled
                                            ? true
                                            : false
                                        }
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          handleRestartWhatsAppSession(whatsApp)
                                        }
                                      >
                                        Restore
                                      </Button>}


                                  </TableCell>
                                )}
                              />

                              {/* <Can
                                role={user.profile}
                                perform="connection-button:show"
                                yes={() => (
                                  <TableCell align="center">
                                    <CustomToolTip
                                      title={'Informação da sessão em Megabytes'}
                                      content={
                                        'Tamanho do diretorio da sessão atualizado a cada 5 segundos'
                                      }
                                    >
                                      <div>{whatsApp.sessionSize}</div>
                                    </CustomToolTip>
                                  </TableCell>
                                )}
                              /> */}

                              <TableCell align="center">
                                {format(
                                  parseISO(whatsApp.updatedAt),
                                  'dd/MM/yy HH:mm'
                                )}
                              </TableCell>

                              <TableCell align="center">
                                {whatsApp.isDefault && (
                                  <div className={classes.customTableCell}>
                                    <CheckCircle style={{ color: green[500] }} />
                                  </div>
                                )}
                              </TableCell>

                              <TableCell align="center">
                                <Can
                                  role={user.profile}
                                  perform="show-icon-edit-whatsapp"
                                  yes={() => (
                                    //  disabled={
                                    //       whatsApp.disabled || disabled
                                    //         ? true
                                    //         : false
                                    //     }
                                    <div
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                        border: 'none',
                                        display: 'inline',
                                      }}
                                    >
                                      {(settings &&
                                        settings.length > 0 &&
                                        getSettingValue('editURA') &&
                                        getSettingValue('editURA') ===
                                        'enabled') |
                                        (user.profile === 'master') ? (
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleEditWhatsApp(whatsApp)
                                          }
                                        >
                                          <Edit />
                                        </IconButton>
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  )}
                                />

                                <Can
                                  role={user.profile}
                                  perform="btn-remove-whatsapp"
                                  yes={() => (
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        handleOpenConfirmationModal(
                                          'delete',
                                          whatsApp.id
                                        )
                                      }}
                                    >
                                      <DeleteOutline />
                                    </IconButton>
                                  )}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </Paper>
        </MainContainer>
      )}
    />
  )
}

export default Connections
