import React, { useState, useEffect, useReducer, useCallback, useContext } from "react"
//import openSocket from "socket.io-client"
import { socket } from "../../services/socket"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"

import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import SearchIcon from "@material-ui/icons/Search"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"

import IconButton from "@material-ui/core/IconButton"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import EditIcon from "@material-ui/icons/Edit"

import api from "../../services/api"
import TableRowSkeleton from "../../components/TableRowSkeleton"
import ContactModal from "../../components/ContactModal"
import ConfirmationModal from "../../components/ConfirmationModal/"

import { i18n } from "../../translate/i18n"
import MainHeader from "../../components/MainHeader"
import Title from "../../components/Title"
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper"
import MainContainer from "../../components/MainContainer"
import toastError from "../../errors/toastError"
import { AuthContext } from "../../context/Auth/AuthContext"
import { Can } from "../../components/Can"

import apiBroker from "../../services/apiBroker"
import fileDownload from 'js-file-download'
import ContactCreateTicketModal from "../../components/ContactCreateTicketModal"



const reducer = (state, action) => {


  if (action.type === "LOAD_CONTACTS") {

    const contacts = action.payload
    const newContacts = []

    contacts.forEach((contact) => {

      const contactIndex = state.findIndex((c) => +c.id === +contact.id)

      if (contactIndex !== -1) {
        state[contactIndex] = contact
      } else {
        newContacts.push(contact)
      }

    })

    return [...state, ...newContacts]
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload
    const contactIndex = state.findIndex((c) => +c.id === +contact.id)

    if (contactIndex !== -1) {
      state[contactIndex] = contact
      return [...state]
    } else {
      return [contact, ...state]
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload

    const contactIndex = state.findIndex((c) => +c.id === +contactId)
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1)
    }
    return [...state]
  }

  if (action.type === "RESET") {
    return []
  }
}

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}))

const Contacts = () => {
  const classes = useStyles()
  const history = useHistory()

  const { user } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchParam, setSearchParam] = useState("")
  const [contacts, dispatch] = useReducer(reducer, [])
  const [selectedContactId, setSelectedContactId] = useState(null)
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [isCreateTicketModalOpen, setIsCreateTicketModalOpen] = useState(false)
  const [deletingContact, setDeletingContact] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [settings, setSettings] = useState([])


  const [onQueueStatus, setOnQueueProcessStatus] = useState(undefined)

  const [zipfile, setZipFile] = useState()


  async function handleChange(event) {

    try {

      if (event.target.files[0].size > 1024 * 1024 * 4) {
        alert('Arquivo não pode ser maior que 4 MB!')
        return
      }

      const formData = new FormData()
      formData.append("adminId", user.id)
      formData.append("baseURL", process.env.REACT_APP_BACKEND_URL_PRIVATE,)
      formData.append("frontURL", process.env.REACT_APP_FRONTEND_URL)
      formData.append("identifier", 'contacts_insert_csv')
      formData.append("file", event.target.files[0])

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }

      const bulk_contact_insert = await apiBroker.post("/contacts/bulk/insert", formData, config)

      console.log(bulk_contact_insert.data)

      const onQueueStatus = bulk_contact_insert.data.queueStatus

      setOnQueueProcessStatus(onQueueStatus)


      // history.go(0);
    } catch (err) {
      toastError(err)
    }

  }

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data } = await api.get('/settings')

        setSettings(data.settings)
      } catch (err) {
        toastError(err)
      }
    }
    fetchSession()
  }, [])

  const getSettingValue = (key) => {
    const { value } = settings.find((s) => s?.key === key)
    return value
  }

  useEffect(() => {

    const delayDebounceFn = setTimeout(() => {

      const fetchReportOnQueue = async () => {
        try {

          const insertOnQueue = await apiBroker.get("/contacts/status/insert/onqueue", {
            params: {
              adminId: user.id,
              baseURL: process.env.REACT_APP_BACKEND_URL_PRIVATE,
              identifier: 'contacts_insert_csv'
            }
          })

          if (insertOnQueue && insertOnQueue.data) {

            setZipFile(insertOnQueue.data.app.file)
            setOnQueueProcessStatus(insertOnQueue.data.app.status)
          }
          else {
            setOnQueueProcessStatus('empty')
          }


        } catch (err) {
          console.log(err)
        }
      }

      fetchReportOnQueue()

    }, 500)
    return () => clearTimeout(delayDebounceFn)

  }, [user])



  useEffect(() => {
    dispatch({ type: "RESET" })
    setPageNumber(1)
  }, [searchParam])

  useEffect(() => {


    if (searchParam.trim().length > 0 && searchParam.endsWith(' ')) {
      return
    }



    setLoading(true)
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {

        try {

          if (searchParam.trim().length > 40) {
            return
          }

          const { data } = await api.get("/contacts/", { params: { searchParam, pageNumber, userId: user.id }, })

          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts })
          setHasMore(data.hasMore)
          setLoading(false)

        } catch (err) {
          toastError(err)
        }

      }
      fetchContacts()
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [searchParam, pageNumber])

  useEffect(() => {
    //const socket = openSocket(process.env.REACT_APP_BACKEND_URL)

    const onContactsBulkContacts = (data) => {
      if (data.action === 'update') {

        if (String(data.insertOnQueue.adminId) === String(user.id)) {

          // console.log('-----------------> THE FILE: ', data.insertOnQueue)

          setZipFile(data.insertOnQueue.file)
          setOnQueueProcessStatus(data.insertOnQueue.queueStatus)

          if (data.insertOnQueue.queueStatus === "success") {
            history.go(0)
          }
        }

      }
    }

    socket.on("contactsBulkInsertOnQueueStatus", onContactsBulkContacts)

    const onContactContacts = (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact })
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId })
      }
    }

    socket.on("contact", onContactContacts)

    return () => {
      socket.off("contact", onContactContacts)
      socket.off("contactsBulkInsertOnQueueStatus", onContactsBulkContacts)
    }
  }, [user, history])

  const removeExtraSpace = (str) => {

    str = str.replace(/^\s+/g, '')

    return str.replace(/\s+/g, ' ')
  }

  const handleSearch = (event) => {
    setSearchParam(removeExtraSpace(event.target.value.toLowerCase()))
  }

  const handleOpenContactModal = () => {
    setSelectedContactId(null)
    setContactModalOpen(true)
  }

  const handleCloseContactModal = () => {
    setSelectedContactId(null)
    setContactModalOpen(false)
  }


  const handleSaveTicketOneQueue = useCallback(async (contactId, userId, queueId) => {
    if (!contactId || !userId) {
      console.log("Missing contactId or userId")
      return
    };
    if (!queueId) {
      toast.warning("Nenhuma Fila Selecionada")
      return
    }
    // if (isMounted.current) setLoading(true)
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        userId: userId,
        queueId: queueId,
        status: "open",
      })
      history.push(`/tickets/${ticket.id}`)
    } catch (err) {
      toastError(err)
    }
    // if (isMounted.current) setLoading(false)
  }, [history])

  const handleOpenCreateTicketModal = (contactId) => {

    setSelectedContactId(contactId)

    if (getSettingValue('whatsaAppCloudApi') === 'disabled' && user?.queues?.length === 1) {
      handleSaveTicketOneQueue(contactId, user.id, user.queues[0].id)
    }
    else {
      setIsCreateTicketModalOpen(true)
    }

    // setSelectedContactId(contactId)
    // setIsCreateTicketModalOpen(true)
  }

  const handleCloseCreateTicketModal = () => {
    setIsCreateTicketModalOpen(false)
  }


  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId)
    setContactModalOpen(true)
  }

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`)
      toast.success(i18n.t("contacts.toasts.deleted"))
    } catch (err) {
      toastError(err)
    }
    setDeletingContact(null)
    setSearchParam("")
    setPageNumber(1)
  }

  const handleimportContact = async () => {
    try {
      await api.post("/contacts/import")
      history.go(0)
    } catch (err) {
      toastError(err)
    }
  }


  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1)
  }

  const handleScroll = (e) => {
    if (!hasMore || loading) return
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget



    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore()
    }
  }


  const handleDownload = async () => {

    setOnQueueProcessStatus('downloading')

    try {

      let res = await apiBroker.get(`/contacts/download/${zipfile}`, { responseType: 'blob' })

      if (res) {
        fileDownload(res.data, `${zipfile}`)
        setOnQueueProcessStatus('empty')
      }


    } catch (err) {
      console.log(err)
    }


  }


  const handleError = async () => {

    try {

      alert('Não foi possível inserir a planilha de contatos! Tente novamente ou entre em contato com o suporte.')

      let res = await apiBroker.get(`/contacts/error/insert/onqueue`, {
        params: {
          adminId: user.id,
          baseURL: process.env.REACT_APP_BACKEND_URL_PRIVATE,
          identifier: 'contacts_insert_csv'
        }
      })

      if (res) {
        setOnQueueProcessStatus('empty')
      }


    } catch (err) {
      console.log(err)
    }


  }



  const renderSwitch = (param) => {
    switch (param) {
      case 'empty':
        return (
          <>

            <input
              type="file"
              accept=".csv"
              style={{ display: 'none' }}
              onChange={handleChange}
              id="contained-button-file"
            />

            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                CSV UPLOAD
              </Button>
            </label>

            {/* <Button
              disabled={query && query.length > 0 ? false : true}
              variant="contained"
              color="primary"
              onClick={(e) => {
                handleCSVMessages()
              }}
            >
              {"CSV ALL"}
            </Button> */}
          </>)

      case 'pending' || 'processing':
        return (
          <>
            <span>PROCESSING...</span>
          </>)

      case 'success':
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                handleDownload(e)
              }}
            >
              {'DOWNLOAD'}
            </Button>
          </>)
      case 'error':
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                handleError(e)
              }}
            >
              {'ERROR'}
            </Button>
          </>)
      case 'downloading':
        return (
          <>
            <span>DOWNLOADING...</span>
          </>)


      default:
        return (<><span>WAITING...</span></>)
    }
  }

  return (
    <MainContainer className={classes.mainContainer}>
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      ></ContactModal>
      <ContactCreateTicketModal
        modalOpen={isCreateTicketModalOpen}
        onClose={handleCloseCreateTicketModal}
        contactId={selectedContactId}
      />
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${deletingContact.name
            }?`
            : `${i18n.t("contacts.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          deletingContact
            ? handleDeleteContact(deletingContact.id)
            : handleimportContact()
        }
      >
        {deletingContact
          ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
          : `${i18n.t("contacts.confirmationModal.importMessage")}`}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t("contacts.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />

          <Can
            role={user.profile}
            perform="contacts-page:import-csv-contacts"
            yes={() => (


              <>
                {renderSwitch(onQueueStatus)}
              </>

              // <Button
              //   variant="contained"
              //   color="primary"
              //   onClick={(e) => handleImportCSVContact()}
              // >
              //   IMPORTAR CONTACTO CSV
              //   {/* {i18n.t("contacts.buttons.import")} */}
              // </Button>
            )}
          />

          <Can
            role={user.profile}
            perform="contacts-page:import-contacts"
            yes={() => (
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => setConfirmOpen(true)}
              >
                {i18n.t("contacts.buttons.import")}
              </Button>
            )}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenContactModal}
          >
            {i18n.t("contacts.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t("contacts.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.whatsapp")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.email")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    {<Avatar src={contact.profilePicUrl} />}
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">{contact.number}</TableCell>
                  <TableCell align="center">{contact.email}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleOpenCreateTicketModal(contact.id)}
                    >
                      <WhatsAppIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => hadleEditContact(contact.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <Can
                      role={user.profile}
                      perform="contacts-page:deleteContact"
                      yes={() => (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmOpen(true)
                            setDeletingContact(contact)
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  )
}

export default Contacts