import React, { useState, useContext, useMemo, useEffect } from "react"
import { useHistory } from "react-router-dom"
//import openSocket from "socket.io-client"
import { socket } from "../../services/socket"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core"

import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

import { i18n } from "../../translate/i18n"
import api from "../../services/api"
import ButtonWithSpinner from "../ButtonWithSpinner"
import toastError from "../../errors/toastError"

import { AuthContext } from "../../context/Auth/AuthContext"


import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext"

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
	},
}))

// Receive array of queues arrays
// Return a new array with unique queues from all arrays has passed by the parameter
const queueArraysToOneArray = (array) => {
	if (!array) return []
	const map = {}
	const uniqueQueuesAvailable = []
	array.forEach((queues) => {
		queues.forEach(({ id, name, color }) => {
			if (!map[id]) {
				map[id] = true
				uniqueQueuesAvailable.push({ id, name, color })
			}
		})
	})
	return uniqueQueuesAvailable
}


const TransferTicketModal = ({ modalOpen, onClose, ticketid }) => {
	const history = useHistory()
	const { whatsApps } = useContext(WhatsAppsContext)
	const { user } = useContext(AuthContext)

	const [loading, setLoading] = useState(false)
	const [selectedQueue, setSelectedQueue] = useState('')
	const [selectedUser, setSelectedUser] = useState('')
	const classes = useStyles()

	const [users, setUsers] = useState([])
	const [queues, setQueues] = useState([])
	const [_queues, setQueuesByWhats] = useState([])
	// const isRun = useRef(false) 

	const [itemHover, setItemHover] = useState(-1)
	const [settings, setSettings] = useState([])


	const _queues2 = useMemo(() => {
		if (!whatsApps) return []
		const whatsAppsQueues = whatsApps.map(({ queues }) => queues)
		//const whatsAppsQueues = whatsApps.filter(({ status }) => status === "CONNECTED" ).map(({ queues }) => queues)
		const uniqueQueuesAvailable = queueArraysToOneArray(whatsAppsQueues)
		return uniqueQueuesAvailable
	}, [whatsApps])


	// useEffect(() => {
	// 	if (isRun.current) return
	// 	// setQueues(_queues2)
	// 	isRun.current = true
	// }, [whatsApps])

	useEffect(() => {

		if (selectedUser) {

			let { queues } = users.find(u => +u.id === +selectedUser)
			const userQueues = queues.map((q) => {
				const { id, color, name } = q
				return { id, color, name }
			})

			setQueues(userQueues)
			setSelectedQueue('')
		}
		else {

			if (settings?.find(e => e?.key === 'queueTransferByWhatsappScope')?.value === 'enabled' && !user.transferToOtherQueues) {
				setQueues(_queues)
			}
			else {
				setQueues(_queues2)
			}

			setSelectedUser('')
		}
	}, [selectedUser, settings, _queues2, _queues, users])


	useEffect(() => {
		//const socket = openSocket(process.env.REACT_APP_BACKEND_URL)

		const onSettingsTransferTicketModal = (data) => {
			console.log('settings updated ----------------------------xxxxxxxxxxxx')

			if (data.action === 'update') {
				setSettings((prevState) => {
					const aux = [...prevState]
					const settingIndex = aux.findIndex((s) => s.key === data.setting.key)
					aux[settingIndex].value = data.setting.value
					return aux
				})
			}
		}

		socket.on('settings', onSettingsTransferTicketModal)

		return () => {
			socket.off("settings", onSettingsTransferTicketModal)
		}
	}, [])


	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get('/settings')

				setSettings(data.settings)
			} catch (err) {
				toastError(err)
			}
		}
		fetchSession()
	}, [])


	const handleClose = () => {
		onClose()
	}

	const handleSaveTicket = async e => {
		e.preventDefault()
		if (!ticketid) return
		if (!selectedQueue) return
		setLoading(true)
		try {
			let data = {}

			if (selectedQueue && selectedQueue !== null) {
				data.queueId = selectedQueue
			}


			if (selectedUser) {
				data.userId = selectedUser
			}
			else {
				// test del PARA APARECER NA FILA DE OUTRO ATENDENTE E O MESMO CLICAR EM ACEITAR AO INVES DE ENVIAR PARA ATENDENDO
				data.status = 'pending'
				data.transfer = true
			}

			await api.put(`/tickets/${ticketid}`, data)

			setLoading(false)
			history.push(`/tickets`)
		} catch (err) {
			setLoading(false)
			toastError(err)
		}
	}


	useEffect(() => {

		const delayDebounceFn = setTimeout(() => {

			const fetchUsers = async () => {
				try {


					if (settings?.find(e => e?.key === 'queueTransferByWhatsappScope')?.value === 'enabled' && !user.transferToOtherQueues) {
						const { data } = await api.get(`/users/all`, {
							params: { userId: user.id },
						})

						setUsers(data.users)
						setQueuesByWhats(data.queues)
						setQueues(data.queues)
					}
					else {

						const { data } = await api.get(`/users/all`, {
							params: { profile: 'user', transferToOtherQueues: user.transferToOtherQueues },
						})

						setUsers(data.users)
						setQueues(_queues2)
					}


				} catch (err) {
					console.log(err)
				}
			}

			fetchUsers()

		}, 500)
		return () => clearTimeout(delayDebounceFn)
	}, [settings, _queues2, user.id])

	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
			<form onSubmit={handleSaveTicket}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("transferTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers >
					<FormControl variant="outlined" className={classes.maxWidth} style={{ marginBottom: '8px' }}>
						{/* <InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel> */}

						<InputLabel>{'Usuário'}</InputLabel>

						<Select
							value={selectedUser}
							onChange={e => setSelectedUser(e.target.value)}
							label={'Transfeir para fila'}
						>
							<MenuItem style={{ background: "white", }} value={''}>&nbsp;</MenuItem>
							{users.map((user) => (
								<MenuItem
									key={user.id}
									value={user.id}
								>{user.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl variant="outlined" className={classes.maxWidth}>
						<InputLabel>{i18n.t("transferTicketModal.fieldQueuePlaceholder")}</InputLabel>

						<Select
							value={selectedQueue}
							onChange={(e) => setSelectedQueue(e.target.value)}
							label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
							required
						>
							<MenuItem style={{ background: "white", }} value={''}>&nbsp;</MenuItem>
							{queues?.map((queue) => (
								<MenuItem
									key={queue.id}
									value={queue.id}
									onMouseEnter={() => setItemHover(queue.id)}
									onMouseLeave={() => setItemHover(-1)}
									style={{
										background: queue.id !== itemHover ? "white" : queue.color,
									}}
								>{queue.name}
								</MenuItem>
							))}
						</Select>


					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("transferTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
					>
						{i18n.t("transferTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog >
	)
}

export default TransferTicketModal