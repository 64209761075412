import React, { useState, useCallback, useEffect, useReducer, useContext } from 'react'
import { toast } from 'react-toastify'

//import openSocket from 'socket.io-client'
import { socket } from '../../services/socket'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import {
    Button,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Table,
    TableHead,
    Paper,
} from '@material-ui/core'
import {
    Edit,
    DeleteOutline,
    // Restore
} from '@material-ui/icons'

import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper'
import Title from '../../components/Title'
import TableRowSkeleton from '../../components/TableRowSkeleton'

import CampaignModal from '../../components/CampaignModal'
import ConfirmationModal from '../../components/ConfirmationModal'
import QrcodeModal from '../../components/QrcodeModal'
import { i18n } from '../../translate/i18n'
// import { WhatsAppsContext } from '../../context/WhatsApp/WhatsAppsContext'
import toastError from '../../errors/toastError'

//--------
import { AuthContext } from '../../context/Auth/AuthContext'
import { Can } from '../../components/Can'

import apiBroker from '../../services/apiBroker'




const reducer = (state, action) => {


    if (action.type === "LOAD_CAMPAIGNS") {

        const campaigns = action.payload
        return [...state, ...campaigns]
    }
    if (action.type === "UPDATE_CAMPAIGNS") {

        const campaign = action.payload
        const campaignIndex = state.findIndex((c) => c.id === campaign.id)

        if (campaignIndex !== -1) {

            state[campaignIndex] = { ...state[campaignIndex], ...campaign }

            return [...state]

            // state[campaignIndex] = campaign 

            // return [...state]
        } else {
            return [campaign, ...state]
        }
    }
    if (action.type === "DELETE_CAMPAIGN") {
        const campaignId = action.payload

        const campaignIndex = state.findIndex((c) => c.id === campaignId)
        if (campaignIndex !== -1) {
            state.splice(campaignIndex, 1)
        }
        return [...state]
    }

    if (action.type === "RESET") {
        return []
    }
}

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
        maxWidth: 450,
    },
    tooltipPopper: {
        textAlign: 'center',
    },
    buttonProgress: {
        color: green[500],
    },
}))

const Campaign = () => {
    //--------
    const { user } = useContext(AuthContext)

    const classes = useStyles()

    // const { whatsApps } = useContext(WhatsAppsContext) 

    // const { campaigns, loading } = useContext(WhatsAppsContext)
    const [campaignModalOpen, setCampaignModalOpen] = useState(false)
    const [qrModalOpen, setQrModalOpen] = useState(false)
    const [selectedCampaign, setSelectedCampaign] = useState(null)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)

    const [campaigns, dispatch] = useReducer(reducer, [])

    const [loading, setLoading] = useState(true)

    const confirmationModalInitialState = {
        action: '',
        title: '',
        message: '',
        campaignId: '',
        csv_original_file_name: '',
        open: false,
    }
    const [confirmModalInfo, setConfirmModalInfo] = useState(
        confirmationModalInitialState
    )


    useEffect(() => {
        dispatch({ type: "RESET" })
    }, [])

    useEffect(() => {
        setLoading(true)

        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {

                try {

                    const { data } = await apiBroker.get('/campaign', {
                        params: {
                            adminId: user.id,
                            baseURL: process.env.REACT_APP_BACKEND_URL_PRIVATE,
                            identifier: 'campaign'
                        }
                    })

                    dispatch({ type: "LOAD_CAMPAIGNS", payload: data.campaign })
                    setLoading(false)

                } catch (err) {
                    toastError(err)
                }

            }
            fetchContacts()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [user.id])


    const handleOpenCampaignModal = () => {
        setSelectedCampaign(null)
        setCampaignModalOpen(true)
    }

    const handleCloseCampaignModal = useCallback(() => {
        setCampaignModalOpen(false)
        setSelectedCampaign(null)
    }, [setSelectedCampaign, setCampaignModalOpen])

    const handleStart = async (campaign) => {

        try {
            const { data } = await apiBroker.post(`/campaign/start/${campaign.id}`)

            dispatch({ type: "UPDATE_CAMPAIGNS", payload: data.campaign })

            toast.success('Campanha iniciada com sucesso')

        } catch (err) {
            toastError(err)
        }
    }

    const handleStop = async (campaign) => {

        try {
            const { data } = await apiBroker.post(`/campaign/stop/${campaign.id}`)

            dispatch({ type: "UPDATE_CAMPAIGNS", payload: data.campaign })

            toast.success('Campanha parada com sucesso')

        } catch (err) {
            toastError(err)
        }
    }

    const handleCloseQrModal = useCallback(() => {
        setSelectedCampaign(null)
        setQrModalOpen(false)
    }, [setQrModalOpen, setSelectedCampaign])

    const handleEditCampaign = (campaign) => {
        setSelectedCampaign(campaign)
        setCampaignModalOpen(true)
    }

    const handleOpenConfirmationModal = (action, campaignId) => {
        if (action === 'disconnect') {
            setConfirmModalInfo({
                action: action,
                title: i18n.t('connections.confirmationModal.disconnectTitle'),
                message: i18n.t('connections.confirmationModal.disconnectMessage'),
                campaignId: campaignId,
            })
        }

        if (action === 'delete') {
            setConfirmModalInfo({
                action: action,
                title: i18n.t('connections.confirmationModal.deleteTitle'),
                message: i18n.t('connections.confirmationModal.deleteMessage'),
                campaignId: campaignId,
            })
        }
        setConfirmModalOpen(true)
    }

    const handleSubmitConfirmationModal = async () => {

        if (confirmModalInfo.action === 'delete') {
            try {
                await apiBroker.delete(`/campaign/${confirmModalInfo.campaignId}`, {
                    params: {
                        adminId: user.id,
                        baseURL: process.env.REACT_APP_BACKEND_URL_PRIVATE,
                        identifier: 'campaign',
                    },
                })

                dispatch({ type: "DELETE_CAMPAIGN", payload: confirmModalInfo.campaignId })

                toast.success('Campanha deletada com sucesso')
            } catch (err) {
                toastError(err)
            }
        }

        setConfirmModalInfo(confirmationModalInitialState)
    }

    const renderActionButtons = (campaign) => {
        return (
            <>
                {campaign.status === 'stopped' && (
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => handleStart(campaign)}
                    >
                        Start
                    </Button>
                )}
                {campaign.status === 'running' && (
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => handleStop(campaign)}
                    >
                        Stop
                    </Button>
                )}
            </>
        )
    }

    useEffect(() => {
        //const socket = openSocket(process.env.REACT_APP_BACKEND_URL)

        const onContactsBulkCampaing = (data) => {
            if (data.action === 'update') {

                if (String(data.insertOnQueue.adminId) === String(user.id)) {

                    if (data?.insertOnQueue?.campaign?.status === "stopped") {

                        dispatch({ type: "UPDATE_CAMPAIGNS", payload: data.insertOnQueue.campaign })

                    }
                }

            }
        }

        socket.on("contactsBulkInsertOnQueueStatus", onContactsBulkCampaing)

        const onCampaignCampaign = (data) => {

            if (data.action === 'update') {
                dispatch({ type: "UPDATE_CAMPAIGNS", payload: data.campaign })
            }
        }

        socket.on('campaign', onCampaignCampaign)



        return () => {
            socket.off("campaign", onCampaignCampaign)
            socket.off("contactsBulkInsertOnQueueStatus", onContactsBulkCampaing)
        }
    }, [user.id])

    return (
        <Can
            role={user.profile}
            perform="connections-view:show"
            yes={() => (
                <MainContainer>
                    <ConfirmationModal
                        title={confirmModalInfo.title}
                        open={confirmModalOpen}
                        onClose={setConfirmModalOpen}
                        onConfirm={handleSubmitConfirmationModal}
                    >
                        {confirmModalInfo.message}
                    </ConfirmationModal>

                    <QrcodeModal
                        open={qrModalOpen}
                        onClose={handleCloseQrModal}
                        campaignId={!campaignModalOpen && selectedCampaign?.id}
                    />

                    <CampaignModal
                        open={campaignModalOpen}
                        onClose={handleCloseCampaignModal}
                        campaignId={selectedCampaign?.id}
                        dispatch={dispatch}
                    />

                    <MainHeader>
                        <Title>Campanhas</Title>

                        <MainHeaderButtonsWrapper>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenCampaignModal}
                            >
                                criar campanha
                            </Button>
                        </MainHeaderButtonsWrapper>
                    </MainHeader>

                    <Paper className={classes.mainPaper} variant="outlined">
                        <>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            Name
                                        </TableCell>

                                        <TableCell align="center">
                                            Status
                                        </TableCell>

                                        <TableCell align="center">
                                            Sent
                                        </TableCell>

                                        <TableCell align="center">
                                            Read
                                        </TableCell>

                                        <TableCell align="center">
                                            Start/stop
                                        </TableCell>

                                        <TableCell align="center">
                                            Sender
                                        </TableCell>


                                        <TableCell align="center">
                                            Message
                                        </TableCell>

                                        <TableCell align="center">
                                            {i18n.t('connections.table.actions')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading ? (
                                        <TableRowSkeleton />
                                    ) : (
                                        <>
                                            {campaigns?.length > 0 &&
                                                campaigns.map((campaign) => (
                                                    <TableRow key={campaign.id}>
                                                        <TableCell align="center">
                                                            {campaign.name}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {campaign.status}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {campaign.status === 'stopped' || campaign.status === 'running' || campaign.status === 'success' ? `${campaign.sent}/${campaign.all}` : '0/0'}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {campaign.status === 'stopped' || campaign.status === 'running' || campaign.status === 'success' ? `${campaign.read}` : '0'}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {renderActionButtons(campaign)}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {campaign.whatsapp_sender}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {campaign.message}
                                                        </TableCell>

                                                        {campaign.status !== 'running' ? <TableCell align="center">

                                                            {campaign.status !== 'success' && (
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() =>
                                                                        handleEditCampaign(campaign)
                                                                    }
                                                                >
                                                                    <Edit />
                                                                </IconButton>)}


                                                            <IconButton
                                                                size="small"
                                                                onClick={(e) => {
                                                                    handleOpenConfirmationModal(
                                                                        'delete',
                                                                        campaign.id
                                                                    )
                                                                }}
                                                            >
                                                                <DeleteOutline />
                                                            </IconButton>
                                                        </TableCell> : <TableCell align="center"></TableCell>}



                                                    </TableRow>
                                                ))}
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </>
                    </Paper>
                </MainContainer>
            )}
        />
    )
}

export default Campaign
